<template>
    <rates-data-table
        :socket-connected="socketConnected"
        :last-update="lastUpdate"
        :dataset="actualRates"
        :loading="loading"
        @refresh="loadRates"
    />
</template>

<script>
import RatesDataTable from './Components/RatesDataTable.vue'

export default {
    name: 'RatesPage',
    components: {
        RatesDataTable
    },
    data () {
        return {
            showPairs: [],
            fromSymbol: null,
            toSymbol: null,
            loading: true,
            ratesLoading: true,
            displayMode: null,
            actualRates: [],
            socketConnected: false,
            lastUpdate: new Date()
        }
    },
    computed: {
        selectedAccount: {
            get () {
                return this.$store.state.selectedAccount
            },
            set (account) {
                this.$store.commit('selectAccount', account)
            }
        },
        symbolsFrom () {
            return this.actualRates
                .map(item => `${item.currencyPair}`.split('_')[0].toUpperCase())
                .map((symbol) => { return { value: symbol, text: symbol } })
        },
        symbolsTo () {
            if (!this.fromSymbol) {
                return []
            }
            return this.actualRates
                .filter(item => `${item.currencyPair}`.split('_')[0].toUpperCase() === this.fromSymbol)
                .map(item => `${item.currencyPair}`.split('_')[1].toUpperCase())
                .map((symbol) => { return { value: symbol, text: symbol } })
        }
    },
    mounted () {
        this.loadRates()
        this.connectSocket()
    },
    beforeDestroy () {
        this.leaveAccountChannel(this.selectedAccount)
        this.$socket.disconnect()
    },
    watch: {
        selectedAccount (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$nextTick(() => {
                    this.loadRates()
                    this.leaveAccountChannel(oldValue)
                    this.joinAccountChannel()
                })
            }
        },
        socketConnected (newValue) {
            if (newValue) {
                this.joinAccountChannel()
            }
        }
    },
    methods: {
        joinAccountChannel () {
            if (this.selectedAccount !== null) {
                this.$nextTick(() => {
                    this.$socket.emit('joinAccountChannel', { accountId: this.selectedAccount.account_id })
                })
            }
        },
        leaveAccountChannel (selectedAccount) {
            if (selectedAccount !== null) {
                this.$nextTick(() => {
                    this.$socket.emit('leaveAccountChannel', { accountId: selectedAccount.account_id })
                })
            }
        },
        connectSocket () {
            this.$socket.connect()
        },
        loadRates () {
            if (this.selectedAccount) {
                this.ratesLoading = true
                this.$api.rates.getActualRates(this.selectedAccount.account_id)
                    .then((response) => {
                        if (response.status === 200 && Array.isArray(response.data)) {
                            this.actualRates = response.data
                        }
                    }).catch((error) => {
                        console.error(error)
                    }).finally(() => {
                        this.$nextTick(() => {
                            this.loading = false
                            this.ratesLoading = false
                        })
                    })
            }
        }
    },
    sockets: {
        connect () {
            this.socketConnected = true
            this.joinAccountChannel()
        },
        connect_error () {
            this.socketConnected = false
        },
        disconnect () {
            this.socketConnected = false
        },
        rates (dataset) {
            this.lastUpdate = new Date()
            this.actualRates = this.actualRates.map((entry) => {
                const rate = dataset.filter(item => item.currencyPair === entry.currencyPair)
                if (rate.length > 0) {
                    return { ...entry, ...rate[0] }
                }
                return entry
            })
        }
    }
}
</script>
