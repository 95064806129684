<template>
    <div class="p-4 cyrrus-rounded shadow bg-white">
        <p>
            {{$t('ratesTable.socketConnection')}}
            <span :class="{ 'text-danger': !socketConnected, 'text-success': socketConnected }">
                {{$t(`ratesTable.socket${(socketConnected) ? 'Connected' : 'Disconnected'}`)}}, {{$t('ratesTable.lastUpdate')}}: {{lastUpdate.toLocaleString()}}
            </span>
        </p>
        <data-table
            :responsive="true"
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="rates"
            :lang="lang"
            :paging="false"
            :actions="false"
        />
    </div>
</template>

<script>
const formatter = new Intl.NumberFormat('cs-CZ')

export default {
    name: 'RatesDataTable',
    props: {
        lastUpdate: {
            type: Date,
            required: false,
            default: () => new Date()
        },
        socketConnected: {
            type: Boolean,
            required: false,
            default: false
        },
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        rates () {
            return this.dataset.map(item => {
                const pair = `${item.currencyPair}`.toUpperCase().split('_')
                return {
                    from: pair[0],
                    to: pair[1],
                    priceAsk: Math.floor(10 * 8 * parseFloat(item.priceAsk)) / (10 * 8),
                    priceBid: Math.floor(10 * 8 * parseFloat(item.priceBid)) / (10 * 8),
                    timestamp: item.timestamp
                }
            })
        },
        header () {
            return [
                {
                    text: this.$t('ratesTable.fromSymbol'),
                    data: 'from',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('ratesTable.toSymbol'),
                    data: 'to',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('ratesTable.priceBid'),
                    data: 'priceBid',
                    filterable: true,
                    sortable: true,
                    format (value) {
                        return formatter.format(value)
                    }
                },
                {
                    text: this.$t('ratesTable.priceAsk'),
                    data: 'priceAsk',
                    filterable: true,
                    sortable: true,
                    format (value) {
                        return formatter.format(value)
                    }
                },
                {
                    text: this.$t('ratesTable.timestamp'),
                    data: 'timestamp',
                    filterable: true,
                    sortable: true,
                    format (value) {
                        return (new Date(value)).toLocaleString()
                    }
                }
            ]
        },
        lang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        }
    },
    methods: {
        onRefresh () {
            this.$emit('refresh')
        }
    }
}
</script>

<style>
.form-control {
    border: 1px solid #dee2e6;
}
</style>
